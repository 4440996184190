import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Deload Week Starts Today`}</em></strong></p>
    <p>{`DB Push Jerk 3×12\\@60% 1RM`}</p>
    <p>{`Band Pull Aparts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`7 Rounds for time of:`}</p>
    <p>{`14 DB Deadlifts (40/25)`}</p>
    <p>{`14 Alternating DB Shoulder Press`}</p>
    <p><strong parentName="p">{`*`}{`Bonus WOD`}{`*`}</strong></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Clapping Pushups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is the last day to make up any Pre Open WODs so make sure you
get them in!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 22nd.  Register online at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      